<template>
  <div class="about-us">
    <!--顶部-->
    <header-nav :current="current"></header-nav>
    <div data-v-4e3aa79e="" id="content" class="content box" style="min-height: 581px;">
      <div data-v-4e3aa79e="" class="logo"><img data-v-4e3aa79e=""
                                                src="http://www.vrcs365.com/uploads/20181119/13e2f261c6a929cad7e159d7ffad8a4b.jpg">
      </div>
      <div data-v-4e3aa79e="" class="paragraph"><p style="text-indent: 37px;"><span
        style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">苏州喜安复能健康科技有限公司（喜安）成立于2015年07月08日，由台湾早期疗育及儿童康复领域专家共同发起成立，为台湾儿童发展早期疗育协会大陆合作伙伴，以通过专业协助与资源整合，为儿童与家庭带来喜乐和平安，促进生命尊重与社会和谐为企业宗旨。喜安以企业的思维激活心智障碍服务产业为己任，以早期疗育政策倡议、方案推广、专业训练、家庭及个案服务、学术及实务交流为工作重点，用现代的科学管理方式提升公益行为的信度与效度。协助各类发展迟缓儿童及其家庭进行早期疗育工作，向早期疗育相关人员提供训练课程（包括康复专业人员、家长领域，儿童领域，以及特教从业人员的职前职中培训及职后的继续教育培训），促进国内外发展障碍儿童早期疗育各专业人员的业务交流联谊会和跨专业个案讨论研讨会的达成。</span>
      </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">服务项目包括：儿童康复、特殊教育、疗育咨询、心理咨询、亲职教育讲座、家长支持团体运作、家长辅导、亲师沟通技巧、儿童发展评估、儿童生活职能学习、早期疗育、融合教育、儿童全人教育倡导、大龄身心障碍者职业培训、身障者职业重建、家庭社会工作、个案管理、社工实习、关怀专线、权益倡导、行业联谊活动、小区公益服务等。</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">自2015年12月开始，我们的服务已覆盖中国大陆各省份，在包括北京、天津、黑龙江、吉林、辽宁、山东、河南、江苏、浙江、湖南、湖北、广东、福建、江西、广西壮族自治区、海南等省份举办了近百场针对各类障别亲职照顾者的特殊教育/康复训练课程，包括语言治疗相关、职能治疗相关、高危儿物理治疗相关、音乐治疗相关、融合教育相关、特殊教育相关、正向行为支持（PBS）相关、应用行为分析相关（ABA）、视知觉相关、大龄身心障碍者职业重建相关，等。并持续三年在国内康复领域开办台湾小儿物理治疗认证课程、台湾小儿职能治疗认证课程，以及台湾小儿语言治疗认证课程，五期结训供惠及不同专业方向之全国各地数百家相关医院康复科、医学研究中心、高校特教系及公民办特殊教育机构及其它康复相关单位的数百位从业人员。我们将海外先进的教育理念和技术源源不断地引入大陆，并积极承接山东省特殊教育骨干教师省培项目、亚太职能治疗学术论坛、台湾早疗论文发表大会及公民办特殊教育学校及医疗康复单位的内训项目。</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">为促进特殊教育更好发展，整合资源为广泛性发展障碍儿童提供更专业的支持服务，在高等院校产学研一体化发展背景下，2018年1月由济南大学特殊教育学院发起，由苏州喜安复能健康科技有限公司合作并经济南市民政局批准设立的济南济大喜安发展障碍儿童康复中心正式成立。中心职能主要包括康复评估、康复训练、康复教育、社区康复指导、康复信息咨询、康复宣传、康复培训等。重点为广泛性发展障碍儿童开展个性化、连续性、多方位的康复服务，为其家长提供支持服务；为特殊教育、康复机构的从业人员提供交流分享平台；为高校、教育机构特殊教育专业教师提供科研及学术创新平台，为特殊教育专业学生提供实操实践基地；为社会提供相关知识的宣传、普及等公益性活动。中心设有功能齐全的康复室（言语语言康复室、感觉统合室、自闭症儿童康复室）以及配备有功能完备的康复设备，现有工作人员多为特殊教育专业硕士及以上学历。中心十分重视高等特殊教育院校先进的理论指导，现已与济南大学教育与心理科学学院合作，双方秉承“共商、共建、共享”原则，将大学静态偏重理论的教育模式与康复中心动态偏重实践的康复模式相结合，集高校障碍儿童研究、人才培养与康复中心实践操作、成效评估等功能于一体，打造“先进理论指导”和“专精实践操作”于一体的社会服务机构。</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="text-indent: 37px;"><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">我们是各类发展障碍儿童的智慧支持者！同时继续推进大龄身心障碍者职业重建网络系统的开发。不忘初心，砥砺前行，相信在未来，我们能以更专业的面向将服务带到中国大陆更广阔的省市地区，并嘉惠偏远地区的身心障碍者、家长及特教康复相关的同仁。</span>
        </p>
        <p><span
          style="font-size: 18px; font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; color: rgb(63, 63, 63);">&nbsp;</span>
        </p>
        <p style="line-height: 16px;"><span style="color: rgb(63, 63, 63);"><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px;"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑;">网站：</span></strong></span><span
          style="text-decoration: underline; color: rgb(5, 99, 193); font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px;"><a
          href="http://www.xarw365.com"
          target="_blank"
          style="text-decoration: underline; color: rgb(5, 99, 193); font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px;">www.xarw365.com</a></span></span>
        </p>
        <p style="line-height: 16px;"><br></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;">Email:xarw365@163.com</span></strong></span>
        </p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><br></span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><span
          style="color: rgb(63, 63, 63); font-size: 18px; font-family: 微软雅黑;">订阅号：</span>xarw</span></strong><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;">365</span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><br></span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><span
          style="color: rgb(63, 63, 63); font-size: 18px; font-family: 微软雅黑;">服务号：</span>xarw</span></strong><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;">fw365</span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><br></span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><span
          style="color: rgb(63, 63, 63); font-size: 18px; font-family: 微软雅黑;">大龄服务号：</span>vrcs365</span></strong></span>
        </p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><br></span></strong></span></p>
        <p style="line-height: 16px;"><span
          style="font-family: 微软雅黑, &quot;Microsoft YaHei&quot;; font-size: 18px; color: rgb(63, 63, 63);"><strong><span
          style="color: rgb(63, 63, 63); font-family: 微软雅黑; font-size: 16px;"><span
          style="color: rgb(63, 63, 63); font-size: 18px; font-family: 微软雅黑;">地址：江苏省苏州市姑苏区东吴北路288号</span></span></strong></span>
        </p>
        <p style="line-height: 16px;"><br></p>
        <p><br></p></div>
      <h2>服务项目包括</h2>
      <ul class="service-option">
        <li>
          <div class="icon icon-aboutus1"></div>
          <p>亲职教育讲座、家长支持团体运作、家长辅助、亲师沟通技巧。</p>
        </li>
        <li>
          <div class="icon icon-aboutus2"></div>
          <p>儿童发展评估、儿童生活职能学习、早起疗育、融合教育、儿童全人教育倡导。</p>
        </li>
        <li>
          <div class="icon icon-aboutus3"></div>
          <p>大龄身心障碍者职业培训、身障者职业重建。</p>
        </li>
        <li>
          <div class="icon icon-aboutus4"></div>
          <p>家庭社会工作、个案管理、社工实习、关怀专线、权益倡导等。</p>
        </li>
        <li>
          <div class="icon icon-aboutus5"></div>
          <p>心理咨询、疗育咨询、特殊教育、儿童康复等课程培训。</p>
        </li>
      </ul>
    </div>
    <!--底部-->
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import HeaderNav from '@/components/HeaderNav'
  import FooterNav from '@/components/FooterNav'

  export default {
    name: 'aboutus',
    data() {
      return {
        current: 'aboutus',
        logo: '',
        aboutUs: {
          profile: '',
          service: ''
        }
      }
    },
    mounted: function () {
      var div = document.getElementById('content')
      var h = document.documentElement.clientHeight || document.body.clientHeight
      div.style.minHeight = h - 40 + 'px'
    },
    components: {
      HeaderNav,
      FooterNav
    },
    created() {
      // 获取用户信息
    }
  }
</script>

<style lang="less" scoped>
  @import url('../assets/css/page/aboutus');
</style>
